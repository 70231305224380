@import "~@/styles/variables";

$gutter: 16px;

.wrapper {
  width: calc(100% - 2 * #{$gutter});
  position: fixed;
  left: $gutter;
  bottom: $gutter;
  opacity: 0;
  z-index: $z-index-top;
  visibility: hidden;

  &.hidden {
    display: none;
  }

  @media screen and (min-width: 401px) {
    width: 300px;
  }
}

.card {
  p {
    color: $color-foreground-primary;
  }

  &:global(.nui-card) {
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: $box-shadow-default;
  }
}

.wrapper-enter {
  opacity: 0;
  visibility: visible;
}

.wrapper-enter-active {
  opacity: 1;
  visibility: visible;
  transition: opacity 300ms;
}

.wrapper-enter-done {
  opacity: 1;
  visibility: visible;
}

@include react-transition-phase(
  "wrapper",
  "exit",
  (transform: translateY(110%) translate(0%), opacity: 0 1, visibility: hidden visible),
  $easing: ease-in-out,
  $duration: 300ms
);
