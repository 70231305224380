@import "~@/styles/variables";

.scrollToTopBtn {
  &:global(.nui-button--with-icon-only) {
    visibility: hidden;
    opacity: 0;
    transition: opacity .4s ease-in-out;
    width: 48px;
    bottom: -5px;
    right: 75px;
    text-align: center;
    position: fixed;
    z-index: $z-index-active;

    &:not(:hover) {
      background: #333;
    }
  }

  &.isActive {
    visibility: visible;
    opacity: .9;
  }
}
