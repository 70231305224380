@import "~@/styles/variables";

$menu-link-hover-color: $color-tint-brand-primary;

:global(.nui-menu).menu {
  width: 300px;
  border-top: 3px solid $color-tint-brand-primary;
}

.menulink,
:global(.nui-link).menulink,
:global(.nui-button).menulink {
  @include type-style-bold;

  color: $color-foreground-primary;
  padding: $spacing-stack-16 0;
  text-decoration: none;

  &:hover,
  &:active {
    text-decoration: none;
  }

  &:hover {
    color: $menu-link-hover-color;

    svg {
      fill: $menu-link-hover-color;
    }
  }
}

.dropdownButton {
  composes: menulink;

  :global(.nui-button-label) {
    font-size: $font-size-16;
  }
}

:global(.nui-link).submenulink {
  @include spacing-squish-12-16;

  border-bottom: 1px solid $color-background-secondary;
  font-size: $font-size-16;

  :global(.nui-text) {
    line-height: 1.4;
  }

  &:global(.active) {
    background-color: $color-tint-brand-primary;
    color: $color-foreground-on-tint;
  }
}
