@import "~@/styles/variables";

.root {
  padding-left: 20px;
  margin-top: 0;
  display: flex;
  flex-direction: column;
  gap: $spacing-stack-16;

  // line-height: 1.5em;
}
