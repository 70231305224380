@use "sass:color";
@import "~@/styles/variables";

// taken from neat.com
$bg-color: #F9F9F9;
$bg-color-hover: #F6F6F6;
$item-color: #77787B;
$item-color-hover: #333;
$item-indent: 30px;
$subitem-indent: $item-indent + 9px;
$item-border: 1px solid #DADADA;
$item-height: 35px;

.portal {
  :global(.nui-popover-transition-container) {
    width: 100%;
    top: 51px !important; // NEAT-16826
  }

  :global(.topbanner-visible) & :global(.nui-popover-transition-container) {
    top: calc(51px + $top-banner-height) !important;
  }
}

.popover {
  width: 100%;
}

:global(.nui-menu-item).menuitem {
  background: inherit;
  border-radius: 0;
  height: $item-height;
  color: $item-color;
  padding-left: $item-indent;
  line-height: $item-height;
  padding-right: 40px;

  :global(.nui-text) {
    font-family: Montserrat, sans-serif;
  }

  :global(.nui-icon) {
    fill: $item-color;
    width: 14px;
    height: 14px;
    align-self: center;
  }

  &:hover,
  &:active,
  &--active,
  &.active {
    background: $bg-color-hover;
    color: $item-color-hover;

    > :global(.nui-icon) {
      fill: $item-color;
    }
  }

  li:not(:last-child) & {
    border-bottom: $item-border;
  }
}

.subMenuItem {
  composes: menuitem;
}

:global(.nui-menu).menu {
  background-color: $bg-color;
  padding: 0;
  border-radius: 0;

  .subMenuItem {
    padding-left: $subitem-indent;
  }

  li:last-child .subMenuItem {
    border-bottom: $item-border;
  }
}
