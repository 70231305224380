b,
strong {
  .nui-link--default {
    @include type-style-bold;
  }
}

.nui-type-display--small,
.nui-type-display--medium,
.nui-type-display--large,
.nui-type-display--xlarge {
  font-family: Roboto, sans-serif;
}

.nui-link--primary,
.nui-link--secondary,
.nui-link--success,
.nui-link--danger {
  // perhaps this should be default behavior?
  // relevant with longer labels at small screen widths

  text-align: center;

  &.nui-round {
    border-radius: $border-radius-max;
  }

  &.nui-link--xlarge {
    min-height: 52px;
    height: unset;
    padding-top: $spacing-stack-12;
    padding-bottom: $spacing-stack-12;
  }

  &.nui-link--large {
    min-height: 40px;
    height: unset;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  &.nui-link--medium {
    min-height: 32px;
    height: unset;
    padding-top: 5px;
    padding-bottom: 5px;
  }
}

.nui-button--primary,
.nui-button--secondary,
.nui-button--success,
.nui-button--danger {
  // perhaps this should be default behavior?
  // relevant with longer labels at small screen widths

  text-align: center;

  &.nui-round {
    border-radius: $border-radius-max;
  }

  &.nui-button--xlarge {
    min-height: 52px;
    height: unset;
    padding-top: $spacing-stack-12;
    padding-bottom: $spacing-stack-12;
  }

  &.nui-button--large {
    min-height: 40px;
    height: unset;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  &.nui-button--medium {
    min-height: 32px;
    height: unset;
    padding-top: 5px;
    padding-bottom: 5px;
  }
}

.nui-input:focus {
  outline-offset: 2px;
}

/*
if form input is child of element with .dark-background class,
it will show a more readable pink for error text
*/
.dark-background {
  .nui-intent-danger,
  .nui-intent-success {
    .nui-input {
      border-width: 2px;
    }
  }

  .nui-intent-danger {
    $color-tint-danger-on-dark: #FF9595;

    .nui-form-helper-text {
      color: $color-tint-danger-on-dark;
    }

    .nui-input {
      border-color: $color-tint-danger-on-dark;
    }
  }
}

.nui-alerts_container.nui-responsive-container {
  z-index: 201; // to be on top of the header
}
