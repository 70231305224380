@import "~@theneatcompany/nui/lib/css/main";
@import "variables";
@import "nui-overrides";
@import "recurly";

:root {
  --max-width: 1100px; // ??
}

html,
body {
  max-width: 100vw;
  height: 100%;
  width: 100%;
}

#__next { /* stylelint-disable-line */
  height: 100%;
  width: 100%;
}

body {
  &:not(.standalone-layout) {
    overflow-y: scroll;
  }

  &.mobile-device {
    @include no-auto-zoom-input;
  }

  &.touch-device {
    .nui-checkbox_input:focus + .nui-checkbox_label:after {
      outline: none;
    }

    .nui-checkbox_label,
    .nui-button,
    .nui-link {
      -webkit-tap-highlight-color: rgb(0 0 0 / 0);
      -webkit-tap-highlight-color: transparent;
    }
  }

  &.sign-up-split {
    @media screen and (max-width: 810px) {
      height: auto;
    }
  }
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* background colors */

.bgcolor-brand { background-color: $color-tint-brand-primary; }
.bgcolor-brand-secondary { background-color: $color-tint-brand-secondary; }
.bgcolor-brand-tertiary { background-color: #E9E8FF; }
.bgcolor-brand-darker { background-color: #2230B2; }

.bgcolor-neutral { background-color: $color-tint-neutral-primary; }
.bgcolor-neutral-secondary { background-color: $color-tint-neutral-secondary; }
.bgcolor-neutral-tertiary { background-color: $color-tint-neutral-secondary; }

.bgcolor-background { background-color: $color-background-primary; }
.bgcolor-background-secondary { background-color: $color-background-secondary; }
.bgcolor-background-tertiary { background-color: $color-background-tertiary; }

.bgcolor-foreground { background-color: $color-foreground-primary; }
.bgcolor-foreground-secondary { background-color: $color-foreground-secondary; }
.bgcolor-foreground-tertiary { background-color: $color-foreground-tertiary; }

.bgcolor-gradient { background-image: linear-gradient(180deg, #E9E8FF 0%, #DAEDFA 100%); }

/* text colors */

.c-brand { color: $color-tint-brand-primary; }

.c-background { color: $color-background-primary; }
.c-background-secondary { color: $color-background-secondary; }
.c-background-tertiary { color: #E9E8FF; }

.c-foreground { color: $color-foreground-primary; }
.c-foreground-secondary { color: $color-foreground-secondary; }
.c-foreground-tertiary { color: $color-foreground-tertiary; }

/* default text sizes */

p,
li,
h4 {
  color: $color-foreground-secondary;
}

h1 {
  font-size: 40px;

  @include bp-mobile { font-size: 36px; }
}

h2 {
  font-size: 32px;

  @include bp-mobile { font-size: 28px; }
}

h3 {
  font-size: 24px;

  @include bp-mobile { font-size: 21px; }
}

h4 {
  font-size: 24px;
  font-weight: normal;

  @include bp-mobile { font-size: 21px; }
}

.w-2-4,
.w-3-6,
.w-1-2 {
  width: 50%;

  @include bp-mobile { width: 100%; }
}

.w-full {
  width: 100%;
}

.w-1-4 {
  width: 25%;

  @include bp-mobile { width: 100%; }
}

.w-1-5 {
  width: 20%;

  @include bp-mobile { width: 100%; }
}

.w-2-5 {
  width: 40%;

  @include bp-mobile { width: 100%; }
}

.w-3-5 {
  width: 60%;

  @include bp-mobile { width: 100%; }
}

.w-3-4 {
  width: 75%;

  @include bp-mobile { width: 100%; }
}

.w-4-5 {
  width: 80%;

  @include bp-mobile { width: 100%; }
}

.w-1-3,
.w-2-6 {
  width: 33.33%;

  @include bp-mobile { width: 100%; }
}

.w-2-3,
.w-4-6 {
  width: 66.66%;

  @include bp-mobile { width: 100%; }
}

.w-5-6 {
  width: 83.33%;

  @include bp-mobile { width: 100%; }
}

.w-1-6 {
  width: 16.66%;

  @include bp-mobile { width: 100%; }
}

/* helpers */

.hidden { display: none; }
.block { display: block; }

.hidden-mobile {
  @include bp-mobile {
    display: none;
  }
}

/* account for header bar when jumping to section on page */
.anchor-link {
  position: relative;
  top: -60px;
}

.absolute {
  position: absolute;
  left: 0;
  top: 0;
}
.relative { position: relative; }

.sticky {
  position: sticky;
  top: 0;
}

.shadow-card {
  box-shadow: $box-shadow-card;
}

.text-center {
  text-align: center;
}

.text-bold {
  @include type-style-bold;
}

.image-center {
  margin-left: auto;
  margin-right: auto;
}

.self-center {
  align-self: center;
}

.self-start {
  align-self: flex-start;
}

@each $size in $font-sizes {
  .f-#{$size} { font-size: #{$size}px; }
}

@include max-w-sizes;

@each $size in $col-sizes {
  .col-#{$size} { flex-basis: #{$size}#{"%"}; }
}

@each $size in $spacing-sizes {
  .gap-#{$size} { gap: #{$size}px; }
}

@include spacing-classes;

.bg-blob {
  background-image: url("../../public/purple-blob.svg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
}

.policy-text {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    margin-bottom: 1em;
  }

  li {
    margin-bottom: 7px;
  }
}

.grecaptcha-badge {
  visibility: hidden !important;
}
