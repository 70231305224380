@import "~@/styles/variables";

.topBarContainer {
  text-align: center;
  background: #515EDA;
  color: #FFF;
  padding: 12px;
  position: fixed;
  width: 100%;
  height: $top-banner-height;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;

  button {
    color: #FFF;
  }

  p {
    margin-top: 10px;
  }
}

@media only screen and (max-width: 540px) {
  .topBarContainer {
    font-size: 14px;
  }
}
