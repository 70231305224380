@import "~@/styles/variables";

.root:global(.nui-text) {
  &.normal {
    font-weight: $font-weight-400;
  }

  &.bold {
    @include type-style-bold;
  }

  &.noMargin {
    margin-bottom: 0;
  }

  &.responsive {
    &:is(h1) {
      @include bp-mobile { font-size: $font-size-32; }
    }

    &:is(h2) {
      @include bp-mobile { font-size: $font-size-24; }
    }

    &:is(h3, h4) {
      @include bp-mobile { font-size: 21px; } // ?
    }
  }
}
