@import "~@/styles/variables";

.root {
  list-style-type: none;
  padding: 0;
  margin: 0;
  max-width: 450px;
}

.li {
  background-repeat: no-repeat;
  background-position: left;
  padding-left: $spacing-inline-32;
  margin-bottom: $spacing-stack-12;
  background-size: 24px;
  color: $color-foreground-secondary;

  &:last-child {
    margin-bottom: 0;
  }

  // @TODO on neat.com the icon-list class sets the check svg as the default icon, but we haven't used it yet

  .root.small & {
    font-size: $font-size-14;
    background-size: 12px;
    padding-left: $spacing-inline-16;
    background-position: left 4px;
  }

  .root.circleIcon & {
    background-image: url("/pricing/list-icons/circle-bullet.svg");
  }
}
