@import "~@/styles/variables";

.container {
  display: flex;
  justify-content: space-evenly;
}

.header {
  position: relative;
  top: 0;
  z-index: 200;
  background-color: rgb(255 255 255 / .8);
  height: 50px;
  padding: 0 $spacing-inline-32;
  display: flex;
  justify-content: center;

  &:global(.headerWithBar) {
    transform: translateY($top-banner-height);
  }
}

.content {
  display: flex;
  align-items: center;
  gap: $spacing-inline-32;
  max-width: 1100px;
  flex: 1;
}

.nav {
  display: none;
  align-items: center;
  justify-content: space-between;
  flex: 1;
}

.logo {
  @media screen and (min-width: 840px) {
    margin-right: $spacing-inline-8;
  }

  @media screen and (min-width: 960px) {
    margin-right: $spacing-inline-24;
  }

  @media screen and (min-width: 1024px) {
    margin-right: 48px;
  }
}

.mobileHeaderNav {
  margin-left: auto;
}

@include bp-non-mobile {
  .header {
    position: sticky;
  }

  .nav {
    display: flex;
  }

  .mobileHeaderNav {
    display: none;
  }
}
