@use "sass:color";
@import "~@/styles/variables";

.sectionWrapper {
  background-color: $color-foreground-secondary;

  .noNav {
    justify-content: space-between;
  }
}

.cols {
  padding: 96px 8px 56px;

  @include bp-mobile {
    gap: $spacing-stack-32;
  }

  .col {
    flex-grow: 0;

    @media only screen and (min-width: 768px) and (max-width: 1024px) {
      flex-basis: 33.33%;
    }
  }
}

.navLink:global(.nui-link) {
  color: $color-foreground-on-tint;
  align-self: start;

  &:hover,
  &:focus,
  &:active {
    color: color.adjust($color-foreground-on-tint, $lightness: -10%);
  }

  &:global(.active) {
    color: $color-tint-warning-secondary;
  }
}

.subscribeInput {
  @include button-style-secondary;
  @include button-size-medium;

  border-radius: $border-radius-max;
  align-self: flex-start;
}

.listHeader {
  margin-bottom: $spacing-stack-4;
}

.copyrightWrapper {
  padding-bottom: $spacing-stack-16;
}
