.recurly-element {
  width: 100%;
  padding: 0;
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 0;
  border-radius: 6px;
  line-height: normal;
  border: 1px solid #E5DFDB;
  height: 38px;
  box-shadow: rgb(0 0 0 / .2) 0 1px 3px 0 inset;
}

.recurly-element-invalid,
.recurly-element.error {
  border: 1px solid #D82D46 !important;
  border-radius: 6px;
}

.has-select .recurly-element {
  box-shadow: none;
  border: 1px solid #CFCFDF;
}

.has-select .recurly-element:hover {
  background-color: #F2F2F2;
}
