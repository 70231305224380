@import "~@/styles/variables";

.base {
  display: flex;
}

.row {
  display: flex;
  width: 100%;

  &:not(.bp-mobile-small) {
    @include bp-mobile {
      flex-direction: column;

      &.noMobileGap {
        gap: 0;
      }
    }
  }

  &.bp-mobile-small {
    @include bp-mobile("small") {
      flex-direction: column;

      &.noMobileGap {
        gap: 0;
      }
    }
  }
}

.rows {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.column {
  display: flex;
  flex-direction: column;
  flex: 0 0;
  max-width: 100%;

  &.fill {
    flex-grow: 1;
  }

  &.shrink {
    flex-shrink: 1;
  }
}

@each $size in $col-sizes {
  .col-#{$size} {
    flex-basis: #{$size}#{"%"};
    min-width: 0; // prevents overflow. maybe should have just used width instead of flex-basis??

    // @TODO 33.33 66.67 etc?

    &.bp-mobile-small {
      @include bp-mobile("small") {
        flex-basis: 100%;
      }
    }

    &:not(.bp-mobile-small) {
      @include bp-mobile {
        flex-basis: 100%;
      }
    }
  }
}

.columns {
  display: flex;
  width: 100%;

  &:not(.bp-mobile-small) {
    @include bp-mobile {
      align-items: stretch;
      flex-direction: column;

      &.noMobileGap {
        gap: 0;
      }
    }
  }

  &.bp-mobile-small {
    @include bp-mobile("small") {
      align-items: stretch;
      flex-direction: column;

      &.noMobileGap {
        gap: 0;
      }
    }
  }
}

@include gap-sizes;
@include max-w-sizes;

.wrap {
  flex-wrap: wrap;
}

.align-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.justify-start {
  justify-content: flex-start;
}

.center {
  align-items: center;
  justify-content: center;
}
