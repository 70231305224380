@import "~@/styles/variables";

.wrapper {
  width: 100%;
}

.content {
  width: 100%;
  max-width: 1100px;
  margin-left: auto;
  margin-right: auto;

  &.responsive {
    @include bp-mobile {
      padding-left: $spacing-inline-16;
      padding-right: $spacing-inline-16;
    }
  }
}
