@import "~@/styles/variables";

.content {
  &.padded {
    padding: 0 $spacing-inline-32;
  }

  &.topBorder {
    border-top: 3px solid $color-tint-brand-primary;
  }

  &.standalone {
    height: 100%;
    width: 100%;
  }

  :global(.topbanner-visible) &.standalone {
    padding-top: $top-banner-height;
  }
}

.app {
  height: 100%;
  width: 100%;

  :global(.topbanner-visible) & > :nth-child(3) {
    margin-top: $top-banner-height;
  }
}
